/** FIXME: remover a instancia axiosInstancePlan quando o servico for mergeado */
import { axiosInstanceWithAuth } from '../../../../../../services/axios-instance';

export const RequestPagSeguro = {
  postCheckout: async (params: any) => {
    const { reference, advertisementId } = params;
    return await axiosInstanceWithAuth
      .post('/planUser', { reference, advertisementId })
      .then(response => response)
      .catch(error => error);
  },
};

export const RequestChoicePlans = {
  postChoicePlans: async (params: any) => {
    /** FIXME - utilizar axiosInstancePagSeguro quando o servico de planos for mergeado */
    const { planUserId, transactionCode } = params;
    return await axiosInstanceWithAuth
      .post('/checkout', { planUserId, transactionCode })
      .then(response => response)
      .catch(error => error);
  },
};
