import { map, get } from 'lodash';
import { slugFormat } from '../../../utils/functions';
import { ResponseServicesData } from '../types';

export const servicesStoreAdapter = (stores: ResponseServicesData[]) => {
  const adaptedStores = map(stores, store => {
    const urlCompanyLogo = get(store, 'user.urlCompanyLogo', '');
    const name = get(store, 'user.fantasyName', '') || get(store, 'user.name', '');
    const srcImg = urlCompanyLogo ? `${process.env.FTP_IMAGENS}${urlCompanyLogo}` : '';
    const description = get(store, 'description', '');
    const city = get(store, 'user.city', '');
    const state = get(store, 'user.state', '');
    const cepAddress = get(store, 'user.cep', '');
    const district = get(store, 'user.addressDistrict', '');
    const street = get(store, 'user.address', '');
    const services = get(store, 'services', []);
    const ratingValue = Number(get(store, 'note', 0));
    const ratings = get(store, 'ratings', []) || [];
    const linkToStore = slugFormat(`/loja/${state}/${city}/${name}/${store.user.id}`);
    const businesshours = get(store, 'businesshours', []);
    const phone = get(store, 'user.phone', '');
    const additionalAddressData = get(store, 'user.additionalAddressData', '');

    return {
      id: store.id,
      name,
      srcImg,
      description,
      city,
      cepAddress,
      district,
      street,
      services,
      ratingValue,
      ratings,
      linkToStore,
      businesshours,
      phone,
      additionalAddressData,
    };
  });

  return adaptedStores;
};

export const removeLastBarOnUrl = (url: string) => {
  if (url.substring(url.length - 1) !== '/') {
    return url;
  }
  return url.substring(0, url.length - 1);
};
