import React from 'react';
import { formatCurrency } from '../../../../../utils/formatNumber';

interface Props {
  isCompany?: boolean;
  price: number;
  optionPrice: string | null;
}

export const PlanPriceTag = ({ price, isCompany, optionPrice }: Props) => {
  const priceToUse = optionPrice ? optionPrice : price;
  const formattedPrice = formatCurrency(priceToUse.toString());
  const formattedDescription = isCompany ? 'Por mês' : 'No boleto ou até 12x no cartão';
  return (
    <section className="w-40 w-100-m w-100-l tc-m tc-l">
      <p className="f18 f26-m f26-l fw6 red ma0 lh-copy-m lh-copy-l">{formattedPrice}</p>
      <p className="f11 f12-m f12-l mb24-m mb24-l fw3 boulder ma0 lh-copy-m lh-copy-l">
        {formattedDescription}
      </p>
    </section>
  );
};
