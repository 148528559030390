import { axiosInstanceWithAuth } from '../../../services/axios-instance';

const HEADERS_CONFIG = { headers: { Authorization: process.env.TOKEN_JWT } };

export const updateStatusPlanPJ = async (
  status: number,
  userId: number | string,
  reference: string,
) => {
  try {
    const response = await axiosInstanceWithAuth.put(
      '/user/plan/admin/changeStatus',
      { status, userId, reference },
      HEADERS_CONFIG,
    );
    return response.status;
  } catch (error: any) {
    return error.response;
  }
};

export const updateStatusPlanPF = async (
  status: number,
  advertisementId: number | string,
  reference: string,
) => {
  try {
    const response = await axiosInstanceWithAuth.put(
      '/advertisement/admin/changeStatus/',
      { status, advertisementId, reference },
      HEADERS_CONFIG,
    );
    return response.status;
  } catch (error: any) {
    return error.response;
  }
};

export const updateStatusPlanPJParts = async (
  status: number,
  userId: number | string,
  reference: string,
) => {
  try {
    const response = await axiosInstanceWithAuth.put(
      '/user/parts/plan/admin/changeStatus',
      { status, userId, reference },
      HEADERS_CONFIG,
    );
    return response.status;
  } catch (error: any) {
    return error.response;
  }
};

export const updateStatusPlanPFParts = async (
  status: number,
  advertisementId: number | string,
  reference: string,
) => {
  try {
    const response = await axiosInstanceWithAuth.put(
      '/user/parts/plan/admin/changeStatus',
      { status, advertisementId, reference },
      HEADERS_CONFIG,
    );
    return response.status;
  } catch (error: any) {
    return error.response;
  }
};

export const getStatusAuthorizationPagseguro = async (userId: number) => {
  try {
    const response = await axiosInstanceWithAuth.get(
      `/user/authorization/pagseguro/${userId}`,
      HEADERS_CONFIG,
    );
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};

export const getAuthorizationPagseguro = async (redirectURL: string) => {
  try {
    const response = await axiosInstanceWithAuth.get('/orders/authorizations/pagseguro', {
      params: { redirectURL },
    });
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};

export const postNotificationPagseguro = async (notificationCode: string) => {
  try {
    const response = await axiosInstanceWithAuth.post(
      '/orders/notification/authorizations/pagseguro',
      { notificationCode },
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createMercadoPagoPayment = async (data: any, onError) => {
  try {
    const { payment_method_id } = data;

    if (payment_method_id === 'pix') {
      const response = await axiosInstanceWithAuth.post('/checkout/createPixPayment', {
        ...data,
      });
      return response;
    }

    const response = await axiosInstanceWithAuth.post('/checkout/createCreditCardPayment', {
      ...data,
    });
    return response;
  } catch (e) {
    onError();
    return e;
  }
};

export const infoPix = async (paymentId, planUserId, onSuccess, onError) => {
  try {
    const response = await axiosInstanceWithAuth.get(
      `/checkout/infoPix/${paymentId}?planUserId=${planUserId}`,
    );
    onSuccess();
  } catch (e) {
    onError();
  }
};
