import React from 'react';

interface Props {
  title: string;
  subtitle?: string;
}

export const PlanTitle = ({ title, subtitle }: Props) => {
  return (
    <section className="w-60 w-40-ns w-100-m w-100-l abbey self-center tc-m tc-l">
      <h2 className="f18 f24-m f24-l fw6 ttu ma0 lh-copy">{title}</h2>
      <h3 className="f12 f14-m f14-l fw4 fw6-m fw6-l ma0 lh-copy">{subtitle}</h3>
    </section>
  );
};
