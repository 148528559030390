import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import last from 'lodash/last';
import map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import { Button } from '../../../../../components/Button';
import { Select } from '../../../../../components/Select';
// TODO: implmentar chamada da api backend
import { useScript } from '../../../../../utils/hooks';
import { UserData } from '../../../../Login/components/SignIn/store/user';
import { TypeStorePlan } from '../../../store/selectedPlan';
import { Plan } from '../types/plancard';
import { PlanFeature } from './PlanFeature';
import { PlanPriceTag } from './PlanPriceTag';
import { PlanTitle } from './PlanTitle';
import { RequestPagSeguro, RequestChoicePlans } from './services/PagSeguro';
import styles from './style.module.css';

interface IUser {
  profile?: UserData;
}

interface Props {
  data: Plan;
  className?: string;
  isCompany?: boolean;
  selectedPlan?: TypeStorePlan;
  user?: IUser;
  onClickPlan?: (ev: { preventDefault: () => void }) => void;
  setLoader?: (loading: boolean) => void;
}

export const PlanCard = inject(
  'selectedPlan',
  'user',
)(
  observer(({ user, selectedPlan, data, className, isCompany, onClickPlan, setLoader }: Props) => {
    useEffect(() => {
      const query = typeof window !== 'undefined' ? last(window.location.pathname.split('/')) : '';
      if (query && query !== 'planos') {
        setAdvId(parseInt(query, 10));
      }
    }, []);

    const { title, subtitle, features, isFeatured, options, price, reference } = data;

    const lightboxProccess = process.env.PAGSEGURO_SANDBOX;
    const [optionPrice, setOptionPrice] = useState(null);
    const [option, setOption] = useState<any>();

    const [lightbox, setLightbox] = useState('');

    const [loaded, error] = useScript(lightbox);

    const [advId, setAdvId] = useState<number>(0);

    useEffect(() => {
      setLightbox(lightboxProccess);
    }, [loaded, error, lightbox]);

    const cardWrapper = cx(
      'w-100 ba b--gray br10 pv12-m pv12-l bg-white',
      styles.cardWrapper,
      {
        [styles.featuredCard]: isFeatured && !isCompany,
      },
      className,
    );

    const superiorSection = cx(
      'flex flex-column-m flex-column-l pv12 ph12 ph24-m ph24-l bb bb-0-m bb-0-l b--gray justify-between',
      {
        [styles.superiorSection]: !isCompany,
        [styles.superiorSectionPJ]: isCompany,
        pl8: isFeatured,
      },
    );

    const inferiorSection = cx(
      'flex flex-column-m flex-column-l pa12 pt0-m pt0-l justify-between items-center',
      styles.inferiorSection,
      {
        pl8: isFeatured,
      },
    );

    const featuresSection = cx(
      'abbey w-60 w-50-ns w-100-m w-100-l bl bl-0-m bl-0-l bb-m bb-l b--gray pl12 pl0-m pl0-l pv24-m pv24-l',
      styles.dividingBar,
      styles.planFeatures,
    );

    const handleOptionPrice = (e: any) => {
      setOptionPrice(e.price);
      setOption(e);
    };

    const planFeatures = () => {
      if (!options || isCompany) {
        return map(features, feature => (
          <PlanFeature
            key={feature.description}
            description={feature.description}
            isAvailable={feature.availability}
          />
        ));
      }
      return (
        <div>
          <p className="f12 fw3 ma0 mb4">Selecione o pacote de dias:</p>
          <Select options={options} title="Período" onChange={handleOptionPrice} />
        </div>
      );
    };

    const useOptionPrice = options ? optionPrice : null;

    const handleRequest = async () => {
      const params = {
        reference: option ? option.reference : reference,
        advertisementId: advId,
      };

      return await RequestPagSeguro.postCheckout(params)
        .then(resp => {
          return resp.data;
        })
        .catch(err => {
          return err;
        });
    };

    const handleClick = async (ev: { preventDefault: () => void }) => {
      if (setLoader) {
        setLoader(true);
      }
      let response: { code?: string; planId?: number } = {};
      if (user && user.profile && !user.profile.admin) {
        response = await handleRequest();
      }

      const callback = {
        success: (transactionCode: any) => {
          const param = {
            planUserId: response.planId,
            transactionCode,
          };
          RequestChoicePlans.postChoicePlans(param)
            .then(resp => {
              navigate('/meus-anuncios');
              return resp;
            })
            .catch(err => {
              navigate('/meus-anuncios');
              return err;
            });
        },
      };

      const adapterPlan = {
        ...data,
        id: advId,
      };

      if (selectedPlan) {
        selectedPlan.setSelectedPlan(adapterPlan);
      }

      // if (onClickPlan) {
      //   onClickPlan(ev);
      // }

      if (loaded && !error && user && user.profile && !user.profile.admin) {
        console.log(selectedPlan);
        const isMobile =
          typeof window.orientation !== 'undefined' ||
          navigator.userAgent.indexOf('IEMobile') !== -1 ||
          /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        const pagSeguroRedirect = () => {
          if (process.env.PAGSEGURO_LIGHTBOX_NOT_SUPPORTED && response?.code) {
            window.location.href = process.env.PAGSEGURO_LIGHTBOX_NOT_SUPPORTED + response.code;
          }
        };

        navigate(`/processar-pagamento?planId=${response.planId}`);

        // window.location.replace(
        //   `/planos-manuntencao?email=${user.profile.email}&plano=${
        //     selectedPlan.getSelectedPlan().title
        //   }&nome=${user.profile.name}`,
        // );

        // if (isMobile) {
        //   pagSeguroRedirect();
        // } else {
        //   try {
        //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //     // @ts-ignore
        //     PagSeguroLightbox(response.code, callback);
        //   } catch (error) {
        //     pagSeguroRedirect();
        //   }
        // }
      }
    };
    return (
      <section className={cardWrapper}>
        <section className={superiorSection}>
          <PlanTitle title={title} subtitle={subtitle} />
          <section className={featuresSection}>{planFeatures()}</section>
        </section>
        <section className={inferiorSection}>
          <PlanPriceTag price={price} isCompany={isCompany} optionPrice={useOptionPrice} />
          <section className={styles.buttonSize}>
            <Button
              onClick={handleClick}
              green
              resetClass
              classButton="fw7 ph32-m ph32-l ttu f14 f18-m f18-l"
            >
              Escolher plano
            </Button>
          </section>
        </section>
      </section>
    );
  }),
);
