import React from 'react';

interface Props {
  userType: string;
}
export const WarningText = ({ userType }: Props) => {
  if (userType === 'company') {
    return (
      <p className="mb32">
        ATENÇÃO: os planos Perfeito, Certo e Ideal não incluem exibição na página inicial. Existe
        rotatividade de anúncios em todos os planos. Seu anúncio poderá não estar exibido no site em
        100% das sessões.
      </p>
    );
  }
  return (
    <p className="mb32">
      ATENÇÃO: O plano básico NÃO inclui exibição na página inicial. Existe rotatividade de anúncios
      nos planos Destaque e Vende Fácil. Seu anúncio poderá não estar exibido no site em 100% das
      sessões.
    </p>
  );
};
