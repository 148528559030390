import React from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import MediaQuery from 'react-responsive';
import { Nodes, Plano, DirectusPlano } from '../../../../types/graphql';
import { PlanCardGroup } from './PlanCardGroup';
import { formatPlans } from './utils/dataFormatter';

interface Props {
  data?: Nodes<Plano>;
  isCompany?: boolean;
  setLoader?: (loading: boolean) => void;
  onClickPlan?: (ev: { preventDefault: () => void }) => void;
}

export const RenderPlanCards = ({ data, isCompany, onClickPlan, setLoader }: Props) => {
  const pfPlans = orderBy(
    filter(data, plan => plan.type_plan),
    'sort',
    'asc',
  );
  const pjPlans = orderBy(
    filter(data, plan => !plan.type_plan),
    'sort',
    'asc',
  );

  const formattedPfPlans = formatPlans(pfPlans, 1);

  const formattedPjPlans = formatPlans(pjPlans, 2);

  const cardStyle = cx(
    'flex flex-column flex-row-ns flex-wrap-ns justify-start-ns justify-center-m justify-center-l',
  );

  if (isCompany) {
    return (
      <>
        <MediaQuery maxWidth={1023}>
          <PlanCardGroup
            planList={formattedPjPlans}
            isCompany
            largerList
            onClickPlan={onClickPlan}
            setLoader={setLoader}
          />
        </MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          <PlanCardGroup
            planList={formattedPjPlans}
            isCompany
            onClickPlan={onClickPlan}
            setLoader={setLoader}
          />
        </MediaQuery>
        <MediaQuery minWidth={1366}>
          <PlanCardGroup
            planList={formattedPjPlans}
            isCompany
            largerList
            onClickPlan={onClickPlan}
            setLoader={setLoader}
          />
        </MediaQuery>
      </>
    );
  }
  return (
    <div className={cardStyle}>
      <PlanCardGroup planList={formattedPfPlans} onClickPlan={onClickPlan} setLoader={setLoader} />
    </div>
  );
};

export const PlanCards = (props: Props) => {
  const planCardsData = graphql`
    query {
      allDirectusPlano {
        nodes {
          sort
          name
          reference
          number_days
          main_page
          ad_page
          price
          featured
          ad_count
          is_unlimited
          type_plan
        }
      }
    }
  `;

  const renderPlanCards = (data: DirectusPlano) => {
    const {
      allDirectusPlano: { nodes },
    } = data;
    return <RenderPlanCards data={nodes} {...props} />;
  };
  return <StaticQuery query={planCardsData} render={renderPlanCards} />;
};
