import React from 'react';
import cx from 'classnames';
import { GridCell } from '../../../../components/GridCell';
import { StyledModal } from '../../../../components/StyledModal';
import styles from './style.module.css';

interface Props {
  isOpen: boolean;
  controlModal: (ev?: any) => void;
}
export const ModalPlans = ({ isOpen, controlModal }: Props) => {
  const firstImageUrl = `${process.env.FTP_IMAGENS}images/plans/illustration1.png`;
  const secondImageUrl = `${process.env.FTP_IMAGENS}images/plans/illustration2.png`;
  const styleModal = cx('overflow-hidden-m overflow-hidden-l', styles.modalPlansStyle);
  return (
    <StyledModal className={styleModal} isOpen={isOpen} closeModal={controlModal}>
      <div className="abbey pa16 pb32 pa32-ns">
        <p className="ma0 fw2 f26">Seus anúncios em</p>
        <p className="ma0 fw7 f26 red mb32">posições estratégicas.</p>
        <div className="flex flex-column-reverse flex-row-m flex-row-l items-center-m items-center-l">
          <GridCell width={[1, 1, 1 / 2]} className={styles.firstImageClass}>
            <img className="w-100 h-100" src={firstImageUrl} />
          </GridCell>
          <GridCell width={[1, 9 / 12, 5 / 12]} className="ml24-m">
            <p className="ma0 fw2 f30 f36-ns">Destaque na</p>
            <p className="ma0 fw6 f30 f36-ns mb12">página inicial</p>
            <p className="ma0 mb16 mb12 fw3 f16">
              É a posição mais visualizada do site, logo abaixo da busca.
            </p>
          </GridCell>
          <GridCell width={[0, 0, 1 / 12]} />
        </div>
      </div>
      <div
        className="flex flex-column flex-row-m flex-row-l justify-between pa16 pa32-ns pb0 pb0-ns white"
        style={{ backgroundImage: 'linear-gradient(to left, #eb0000, #fe074e)' }}
      >
        <GridCell
          width={[1, 11 / 12, 5 / 12]}
          className="pr16-m mb16 mb0-m flex flex-column justify-center"
        >
          <p className="ma0 fw2 f30 f36-m">Destaque em</p>
          <p className="ma0 fw6 f30 f36-m mb12">páginas diversas</p>
          <p className="ma0 fw3 f16 lh-copy pr12 pr0-m pr0-l">
            Seu anúncio será apresentado como sugestão em várias páginas do site, inclusive de
            outros veículos.
          </p>
        </GridCell>
        <GridCell width={[0, 0, 1 / 12]} />
        <GridCell width={[1, 1, 1 / 2]} className="pb32-m pb32-l">
          <img className="w-100 h-100" src={secondImageUrl} />
        </GridCell>
      </div>
    </StyledModal>
  );
};
