import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import { RawPlanData } from '../types/plancard';

const identifyPlan = (plan: string) => {
  if (plan.indexOf('basico') !== -1) {
    return 'basico';
  }
  if (plan.indexOf('destaque') !== -1) {
    return 'destaque';
  }
  return 'vendeFacil';
};

const setSubtitle = (plan: string) => {
  switch (plan) {
    case 'basico':
      return 'Baixa exposição';
    case 'destaque':
      return 'Média exposição';
    case 'vendeFacil':
      return 'Alta exposição';
  }
};

const generateBasicOptions = (plans: RawPlanData[]) => {
  const findBasicPlans = filter(plans, plan => plan.reference.indexOf('basico') !== -1);
  return map(findBasicPlans, option => ({
    label: `${option.number_days} dias`,
    value: option.number_days,
    price: option.price,
    reference: option.reference,
  }));
};

const groupBasicPlans = (plans: RawPlanData[]) => {
  const filterBasicPlans = filter(plans, plan => identifyPlan(plan.reference) !== 'basico');
  const fetchBasicPlan = find(plans, plan => identifyPlan(plan.reference) === 'basico');
  return fetchBasicPlan ? filterBasicPlans.concat(fetchBasicPlan) : filterBasicPlans;
};

export const formatPlans = (plans: RawPlanData[], type: number) => {
  if (type === 1) {
    const options = generateBasicOptions(plans);
    const planList = groupBasicPlans(plans);
    const formattedPlans = map(planList, plan => {
      const identifiedPlan = identifyPlan(plan.reference);
      const formattedData = {
        id: plan.sort,
        isFeatured: plan.featured,
        features: [
          {
            description: `${plan.number_days} dias de exibição`,
            availability: true,
          },
          {
            description: 'Destaque na página inicial',
            availability: plan.main_page,
          },
          {
            description: 'Destaque em páginas diversas',
            availability: plan.ad_page,
          },
        ],
        price: plan.price,
        title: plan.name,
        subtitle: setSubtitle(identifiedPlan),
        options: identifiedPlan === 'basico' ? options : null,
        reference: plan.reference,
      };
      return formattedData;
    });
    return formattedPlans;
  }

  const formattedPjPlans = map(plans, plan => {
    return {
      id: plan.sort,
      features: [
        {
          description: 'Destaque em páginas diversas',
          availability: plan.ad_page,
        },
        {
          description: 'Destaque na página inicial',
          availability: plan.main_page,
        },
      ],
      price: plan.price,
      title: plan.name,
      subtitle: plan.is_unlimited ? 'Ilimitado' : `${plan.ad_count} anúncios`,
      reference: plan.reference,
    };
  });
  return formattedPjPlans;
};
