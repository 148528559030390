import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../components/Icons';
import { WarningText } from '../WarningText/';
import styles from './style.module.css';

interface Props {
  userType?: string;
}
export const SafeAreaFooter = ({ userType = 'regular' }: Props) => {
  const footerClass = cx('center', styles.footerMaxWidth);
  return (
    <footer className={footerClass}>
      <section className="flex justify-around mb32">
        {/* <section>
          <h2 className="f14 abbey fw3 ma0 lh-copy mb4">Formas de pagamento</h2>
          <Icon name="SvgIconPagseguro" />
        </section> */}
        <section className="flex flex-column items-center justify-center">
          <Icon name="SvgIconSafezone" />
          <p className="ttu f11 sap-green ma0 mt4 fw3">
            Área <b className="fw6">protegida</b>
          </p>
        </section>
      </section>
      <section className="tc f11 boulder">
        <p className="mb24">
          Ao escolher o plano e realizar o pagamento, automaticamente você estará concordando com a{' '}
          <a className="boulder" href="#" title="Política de Publicidade">
            Política de Publicidade
          </a>{' '}
          e{' '}
          <a className="boulder" href="#" title="Privacidade">
            Privacidade
          </a>
          .
        </p>
        <WarningText userType={userType} />
      </section>
    </footer>
  );
};
