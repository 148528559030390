import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { inject, observer } from 'mobx-react';
import QueryString from 'query-string';
import { Container } from '../components/Container';
import { GridCell } from '../components/GridCell';
import { Layout } from '../components/Layout';
import { Link } from '../components/Link';
import { LoaderFullPage } from '../components/Loaders';
import { ModalSuccessAndError } from '../components/ModalSuccessAndError';
import { Topbar } from '../components/Topbar';
import { removeLastBarOnUrl } from '../modules/AsideSearchBarAndContent/utils/adapters';
import { UserData } from '../modules/Login/components/SignIn/store/user';
import { ModalPlans } from '../modules/SubscriptionPlans/components/ModalPlans';
import { PlanCards } from '../modules/SubscriptionPlans/components/PlanCards';
import { PlanCardsParts } from '../modules/SubscriptionPlans/components/PlanCards/parts';
import { SafeAreaFooter } from '../modules/SubscriptionPlans/components/SafeAreaFooter';
import {
  updateStatusPlanPJ,
  updateStatusPlanPF,
  updateStatusPlanPFParts,
  updateStatusPlanPJParts,
  getStatusAuthorizationPagseguro,
  postNotificationPagseguro,
} from '../modules/SubscriptionPlans/services/updateStatusPlan';
import { TypeStorePlan } from '../modules/SubscriptionPlans/store/selectedPlan';
import { TypeClientStore } from '../modules/SubscriptionPlans/store/selectedStore';

interface Props {
  selectedStore: TypeClientStore;
  selectedPlan: TypeStorePlan;
  location: any;
}

interface IUser {
  profile?: UserData;
}

const ENABLE_PLAN = 1;

const SubscriptionPlans = inject(
  'selectedStore',
  'selectedPlan',
  'user',
)(
  observer(({ selectedStore, selectedPlan, location }: Props) => {
    const [userType, setUserType] = useState('');
    const [modalStatus, toggleModal] = useState(false);
    const [modalSE, toggleModalSE] = useState(false);
    const [requestStatus, setRequestStatus] = useState(false);
    const [isLoading, setLoader] = useState(false);
    const [selectType, setSelectType] = useState('vehicle');

    const modalContent = requestStatus
      ? 'Plano ativado com sucesso!'
      : 'Ocorreu um erro na sua solicitação';
    const modalTitle = requestStatus ? 'Parabéns' : 'Tente novamente mais tarde';

    let user: IUser = {};

    if (typeof Storage !== 'undefined' && typeof window !== 'undefined') {
      user = JSON.parse(localStorage.getItem('user') || '');
    }

    useEffect(() => {
      if (user && user.profile && !user.profile.admin && user.profile.type) {
        setUserType(user.profile.type === 1 ? 'regular' : 'company');
      }

      if (user && user.profile && user.profile.admin) {
        const { type } = selectedStore.getClientStore();
        setUserType(type === 1 ? 'regular' : 'company');
      }

      if (window.location.pathname.indexOf('pecas') !== -1) {
        setSelectType('pecas');

        const callAuthorizationPagseguroFlow = async () => {
          await authorizationPagseguroFlow();
        };

        callAuthorizationPagseguroFlow();
      }
    }, []);

    const authorizePagseguro = async () => {
      try {
        const search: any = location.search || '';
        const newSearch: string = removeLastBarOnUrl(search);
        const params: any = QueryString.parse(newSearch);

        const { notificationCode } = params;

        if (notificationCode) {
          const notificationPgSeguro: any = await postNotificationPagseguro(notificationCode);
          const { status } = notificationPgSeguro;
          if (status === 201) {
            return true;
          }
        }
        return false;
      } catch (e) {
        setRequestStatus(false);
        toggleModalSE(true);
        setLoader(false);
      }
      return false;
    };

    const authorizationPagseguroFlow = async () => {
      if (user && user.profile && !user.profile.admin) {
        try {
          setLoader(true);
          const statusAuthorization = await getStatusAuthorizationPagseguro(user.profile.id);

          const { authorizationRequest } = statusAuthorization;
          if (authorizationRequest) {
            setLoader(false);
            return setSelectType('pecas');
          }

          if (await authorizePagseguro()) {
            setRequestStatus(false);
            setLoader(false);
            return setSelectType('pecas');
          }
          setLoader(false);
        } catch (e) {
          setRequestStatus(false);
          toggleModalSE(true);
          setLoader(false);
        }
      }
    };

    const isCompany = userType === 'company';
    const title = isCompany ? 'Planos para a sua loja' : 'Planos autoscar';
    const subtitle = isCompany
      ? 'Assinaturas mensais pensadas para atender a necessidade da sua loja.'
      : 'Para concluir seu anúncio, escolha um dos planos abaixo.';

    const updatePlanPJ = async () => {
      const { reference } = selectedPlan.getSelectedPlan();
      const { id } = selectedStore.getClientStore();

      setLoader(true);

      const service = selectType === 'pecas' ? updateStatusPlanPJParts : updateStatusPlanPJ;

      await service(ENABLE_PLAN, id, reference)
        .then((status: number) => {
          const isOk = status === 200;
          setRequestStatus(isOk);
          toggleModalSE(true);
          setLoader(false);
        })
        .catch((e: any) => {
          setRequestStatus(false);
          toggleModalSE(true);
          setLoader(false);

          console.error(e.error);
        });
      handleNavigation();
    };

    const updatePlanPF = async () => {
      const { id, reference } = selectedPlan.getSelectedPlan();

      setLoader(true);

      const service = selectType === 'pecas' ? updateStatusPlanPFParts : updateStatusPlanPF;

      await service(ENABLE_PLAN, id, reference)
        .then((status: number) => {
          const isOk = status === 200;
          setRequestStatus(isOk);
          toggleModalSE(true);
          setLoader(false);
        })
        .catch((e: any) => {
          setRequestStatus(false);
          toggleModalSE(true);
          setLoader(false);
          console.error(e.error);
        });
      handleNavigation();
    };

    const handleNavigation = () => {
      const urlNavigate = selectType === 'pecas' ? '/pecas' : '';
      navigate(`/meus-anuncios${urlNavigate}`);
    };

    const toggleModalSuccessError = () => {
      toggleModalSE(!modalSE);
      handleNavigation();
    };

    const handleModal = (ev: { preventDefault: () => void }) => {
      toggleModal(!modalStatus);
      ev.preventDefault();
    };

    const handlePlans = async (ev: { preventDefault: () => void }) => {
      setLoader(true);
      const { type } = selectedStore.getClientStore();
      ev.preventDefault();

      if (user && user.profile && user.profile.admin) {
        if (type === 1) {
          await updatePlanPF();
        } else {
          await updatePlanPJ();
        }
      }
      return setLoader(false);
    };

    const renderPlans = () => {
      if (selectType === 'vehicle') {
        return <PlanCards isCompany={isCompany} onClickPlan={handlePlans} setLoader={setLoader} />;
      }
      return (
        <PlanCardsParts isCompany={isCompany} onClickPlan={handlePlans} setLoader={setLoader} />
      );
    };

    const renderInformation = () => {
      if (selectType !== 'pecas') {
        return (
          <Link to="#" className="f14" onClick={handleModal}>
            Veja onde seu anúncio pode aparecer.
          </Link>
        );
      }
      return null;
    };

    return isLoading ? (
      <LoaderFullPage />
    ) : (
      <Layout>
        <Topbar />
        <Container>
          <section className="tc mb40 mt32">
            <h1 className="f20 f24-ns f30-m f30-l abbey ttu ma0 mb8">{title}</h1>
            <p className="f14 f16-ns fw3 boulder ma0 lh-copy mb8">{subtitle}</p>
            {renderInformation()}
          </section>
        </Container>
        <section className="bg-wild-sand pv32 ph16 ph0-ns flex justify-center">
          <GridCell width={[1, 730, 970, 1280]}>{renderPlans()}</GridCell>
        </section>
        <Container className="mt32">
          <SafeAreaFooter userType={userType} />
        </Container>
        <ModalPlans isOpen={modalStatus} controlModal={handleModal} />
        <ModalSuccessAndError
          title={modalTitle}
          subTitle={modalContent}
          isOpen={modalSE}
          success={requestStatus}
          closeModal={toggleModalSuccessError}
          textBtnClose="Ok"
        />
      </Layout>
    );
  }),
);

export default SubscriptionPlans;
