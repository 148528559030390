import React from 'react';
import cx from 'classnames';
import { Icon } from '../../../../../components/Icons';

interface Props {
  description: string;
  isAvailable: boolean;
  className?: string;
}

export const PlanFeature = ({ description, isAvailable, className }: Props) => {
  const icon = isAvailable ? 'SvgIconStepcheck' : 'SvgIconStepError';
  const featureStyle = cx(
    {
      'flex justify-between lh-paragraph lh-solid-m lh-solid-l': isAvailable,
      'dn flex-m flex-l justify-between lh-paragraph lh-solid-m lh-solid-l': !isAvailable,
    },
    className,
  );

  return (
    <div className={featureStyle}>
      <p className="f12 f14-m f14-l fw3 ma0">{description}</p>
      <Icon name={icon} height={16} width={16} className="dn db-m db-l" />
    </div>
  );
};
