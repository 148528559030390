import React, { useState } from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { Collapse } from 'react-collapse';
import { Icon } from '../../../../components/Icons';
import { PlanCard } from './PlanCard';
import { Plan } from './types/plancard';

interface Props {
  planList: any;
  isCompany?: boolean;
  largerList?: boolean;
  onClickPlan?: (ev: { preventDefault: () => void }) => void;
  setLoader?: (loading: boolean) => void;
}

export const PlanCardGroup = ({
  planList,
  isCompany,
  largerList,
  onClickPlan,
  setLoader,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const mapCards = (cards: Plan[]) =>
    map(cards, plan => (
      <PlanCard
        key={plan.id}
        className="mt12 mt0-m mt0-l mr24-ns"
        data={plan}
        isCompany={isCompany}
        onClickPlan={onClickPlan}
        setLoader={setLoader}
      />
    ));

  const controlSection = () => {
    setIsOpen(!isOpen);
  };
  const handleFixedItems = () => {
    const fixedItems = largerList ? planList.slice(0, 4) : planList.slice(0, 3);
    return mapCards(fixedItems);
  };
  const handleCollapseCards = () => {
    const collapseItems = largerList ? planList.slice(4) : planList.slice(3);
    return mapCards(collapseItems);
  };

  const buttonClass = cx(
    'fw6 f18 lh-copy red bn bg-transparent flex items-center mt24 mt12-m ml24-m ml0-l',
  );
  const arrowIconHandler = isOpen ? 'SvgIconArrowUpBold' : 'SvgIconArrowDownBold';
  const arrowTextHandler = isOpen ? 'Recolher' : 'Ver todos os planos';

  if (!isCompany) {
    return <>{mapCards(planList)}</>;
  }

  const checkPlanlistLength = () => {
    if (planList.length > 4) {
      return (
        <>
          <Collapse isOpened={isOpen}>
            <div className="flex flex-wrap mt12-m mt12-l justify-center justify-start-l">
              {handleCollapseCards()}
            </div>
          </Collapse>
          <button className={buttonClass} onClick={controlSection}>
            {arrowTextHandler}
            <Icon className="ml4 ml12-ns" name={arrowIconHandler} width={16} height={16} />
          </button>
        </>
      );
    }
    return;
  };

  return (
    <>
      <div className="flex flex-wrap justify-center justify-start-l">{handleFixedItems()}</div>
      {checkPlanlistLength()}
    </>
  );
};
